import React from 'react'
import Layout from '../components/Layout'
import CallBackBanner from '../components/CallBackBanner'
import PromotionBanner from '../components/PromotionBanner/PromotionBanner'
import GeneralEnquiryForm from '../components/shared/Form/GeneralEnquiryForm'
import img from '../images/industry-sector/team_industry.svg'
import IndustryTemplateLayout from '../components/IndustrySector/IndustryTemplateLayout'
import MainGrid from '../components/styling/MainGrid'
import Breadcrumb from '../components/shared/Breadcrumb/Breadcrumb'
import IndustryLeftCol from '../components/IndustrySector/IndustryLeftCol'
import IndustryRightCol from '../components/IndustrySector/IndustryRightCol'
import IndustryDescription from '../components/IndustrySector/IndustryDescription'
import logo from '../images/homepage/popin-anim/retailer.svg'
import CaseStudyContent from '../components/CaseStudy/CaseStudyContent'

const breadcrumbData = [
  {
    title: 'Home',
    url: '/',
  },
  {
    title: 'About us',
    url: '/about-us',
  },
  {
    title: 'Case Studies',
    url: '/case-studies',
  },
  {
    title: 'Beaumont Seymour',
    url: '/beaumont-seymour',
  },
]

const Beaumont = () => {
  return (
    <Layout
      activeLink="/case-studies"
      title="R&D Beaumont Seymour"
      description="R&D Beaumont Seymour"
    >
      <MainGrid noPaddingMobile noPaddingTablet>
        <Breadcrumb data={breadcrumbData} />
      </MainGrid>
      <IndustryTemplateLayout>
        <IndustryLeftCol
          category="Case Studies"
          title="Beaumont Seymour"
          backLink="/case-studies"
        />
        <IndustryRightCol>
          <IndustryDescription title="Beaumont Seymour" />
          <CaseStudyContent
            heading="Accountancy"
            paragraph='"We have been working with Kevin Auchoybur and his team successfully for the last 12 months with many of our clients.'
            // imgDesktop={img}
            // imgMobile={img}
            alt="Aeroplane"
            logo={logo}
            content={[
              <div>
                <p>
                  The professionalism, knowledge and care applied in relation to
                  all of the R&D Tax Credit claims we have submitted to date is
                  quite simply second to none.
                </p>
                <p>
                  We do not hesitate to recommend RDA to any of our
                  clients for first-class advice and assistance for their R&D
                  Tax Credit claims."
                </p>
                <h6>Ivan Stanmore – FCCA, Director</h6>
              </div>,
            ]}
          />
        </IndustryRightCol>
      </IndustryTemplateLayout>
      <PromotionBanner />
      <GeneralEnquiryForm />
      <CallBackBanner />
    </Layout>
  )
}

export default Beaumont
